<template>
  <div class="d-flex justify-content-end align-items-center">
    <!--btn add | update | delete-->
    <b-button variant="primary" class="mx-1" @click="submit">تعديل</b-button>
    <b-button variant="outline-primary" @click="$router.go(-1)">تراجع </b-button>
    <b-button variant="danger" class="ml-1" @click="deletePack(packageDto.id)"
      >حذف</b-button
    >
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      packageDto: (state) => state.codes.packageDto,
    }),
  },
  methods: {
    ...mapActions(["updatePackage"]),
    deletePack(id) {
      this.$store.dispatch("deletePackage", [id]);
    },
    submit() {
      this.updatePackage(this.packageDto);
    },
  },
};
</script>
